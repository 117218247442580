import { graphql } from "../gql";

export default graphql(`
  query DivisionRanks {
    ctp_Nfl {
      teams {
        conference
        division
        record {
          conferenceRank
          divisionRank
          nonConference {
            losses
            ties
            wins
          }
          conferenceRecord {
            losses
            ties
            wins
          }
        }
        meta {
          icon
          logo {
            svg
          }
          name
          code
          slug
        }
        teamSchedule {
          games {
            opponentPoints
            opponentTeamCode
            status
            teamPoints
            opponentTeamMeta {
              logo {
                svg
              }
              icon
            }
            weekNumber
            gameTitle
          }
        }
      }
    }
  }
`);
