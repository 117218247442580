"use client";

import { GetMenusQuery } from "@/graphql/gql/graphql";
import { useNavMenus } from "@/utils/hooks/useMenus";
import { filterEmpty } from "@/utils/index";
import { Footer as BaseFooter, type NestedLink } from "@fanduel/og-fe-library";
import { ResponsibleGaming } from "./ResponsibleGaming";
import styles from "./Footer.module.scss";

type FooterProps = {
  initialData?: GetMenusQuery;
};

const Footer = ({ initialData }: FooterProps) => {
  const { menuData } = useNavMenus(initialData);

  const footerMenuData = menuData?.footer;
  const termsMenuData = menuData?.terms?.menuItems ?? [];
  const partnersMenuData = menuData?.copyright?.menuItems ?? [];

  const footerMenu: NestedLink[] = footerMenuData?.menuItems
    ? filterEmpty(footerMenuData.menuItems).map((category) => {
        const children = filterEmpty(category?.children ?? []);
        return { ...category, children };
      })
    : [];

  return (
    <BaseFooter
      footerSitemapLinks={footerMenu}
      footerSitemapClass={styles.footerSitemapLinks}
      keyLinks={termsMenuData}
      partnerLinks={partnersMenuData}
      rgComponent={<ResponsibleGaming />}
    />
  );
};

export default Footer;
