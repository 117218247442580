import { graphql } from "../gql";

const getSubCategoryQuery = graphql(`
  query GetSubCategoryQuery(
    $input: SubCategoryInput!
    $filter: ArticleSearchInput
  ) {
    getSubCategory(input: $input) {
      text
      title
      image {
        url
      }
      sport {
        name
      }
      betMarket {
        type
        props {
          marketId
          marketName
          marketStatus
          marketType
          inPlay
          sgmMarket
          externalMarketId
          selections {
            selectionId
            odds
            handicap
            formattedHandicap
            runnerName
            runnerStatus
            url
            sortPriority
            result {
              type
            }
          }
        }
      }
      articles(filter: $filter) {
        entity {
          id
          title
          sport {
            name
            slug
          }
          url
          thumbnailImageUrl {
            url
          }
          author {
            id
            name
          }
          publishedDate
          description
        }
        cursor
      }
      seo {
        canonicalUrl
        title
        keywords
        synonyms
        description
        noIndex
        image {
          url
        }
        openGraphTag {
          title
          type
          image {
            url
          }
        }
      }
    }
  }
`);

export default getSubCategoryQuery;
