import { graphql } from "../gql";

const getAuthorsQuery = graphql(`
  query getAuthors(
    $input: AuthorSearchInput
    $contentType: Type
    $sport: ContentSportsEnum
  ) {
    getAuthors(input: $input) {
      pageInfo {
        hasNextPage
        cursor
      }
      authors {
        entity {
          authoredContent(contentType: $contentType, sport: $sport) {
            __typename
            slug
            title
            id
            modifiedDate
            ... on Article {
              sport {
                name
                slug
              }
              thumbnailImageUrl {
                url
              }
            }
          }
          id
          name
          slug
          bio
          thumbnailUrl
          hideContent
          profileImageUrl
          favoriteSports {
            name
          }
          socialMedia {
            url
            accountName
            type
          }
        }
      }
    }
  }
`);

export default getAuthorsQuery;
