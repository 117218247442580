import { graphql } from "../gql";

// If there is no argument for week the server will return the games from the current week or the last week if the season is past.

export default graphql(`
  query GetNflSchedule($input: NflScheduleInput) {
    getNflSchedule(input: $input) {
      venueName
      awayTeam {
        leaders {
          rushing {
            name
            carries
            yards
            touchdowns
          }
          receiving {
            name
            receptions
            targets
            yards
            touchdowns
          }
          passing {
            name
            completions
            attempts
            yards
            touchdowns
          }
        }
        stats {
          points
          passingYards
          rushingYards
          interceptionsThrown
          fumblesForced
        }
        winProbability
        code
        averagePassingYardsPerGame
        averageRushingYardsPerGame
        conference
        division
        icon
        logo
        name
        nfDefenseRank
        nfOffenseRank
        numberFireId
        passingYPGRank
        rushingYPGRank
        slug
        shortName
        primaryColor
        secondaryColor
        record {
          wins
          ties
          losses
        }
        teamJersey
      }
      clock
      weather {
        condition
        humidity
        tempInF
        windDirection
        windSpeedInMph
      }
      weekNumber
      seasonType
      broadcastInfo {
        network
      }
      closingLines {
        spread
        total
      }
      odds {
        eventId
        scheduledTime
        url
        away {
          teamCode
          total {
            odd
            url
            value
          }
          moneyLine {
            odd
            url
          }
          spread {
            odd
            url
            value
          }
        }
        home {
          teamCode
          total {
            odd
            url
            value
          }
          moneyLine {
            odd
            url
          }
          spread {
            odd
            url
            value
          }
        }
      }
      quarter
      scheduledTime
      status
      venueType
      homeTeam {
        leaders {
          rushing {
            name
            carries
            yards
            touchdowns
          }
          receiving {
            name
            receptions
            targets
            yards
            touchdowns
          }
          passing {
            name
            completions
            attempts
            yards
            touchdowns
          }
        }
        stats {
          points
          passingYards
          rushingYards
          interceptionsThrown
          fumblesForced
        }
        nfOffenseRank
        nfDefenseRank
        passingYPGRank
        rushingYPGRank
        averagePassingYardsPerGame
        averageRushingYardsPerGame
        code
        conference
        division
        numberFireId
        record {
          wins
          ties
          losses
        }
        primaryColor
        secondaryColor
        icon
        logo
        name
        shortName
        slug
        teamJersey
        winProbability
      }
    }
  }
`);
