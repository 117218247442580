// Sportsbook base url
export const SPORTSBOOK_URL = "https://sportsbook.fanduel.com";

// Page events
export const HOME_PAGE_VIEW = "Home Page Viewed";
export const NEWS_PAGE_VIEW = "News Page Viewed";
export const LEAGUE_PAGE_VIEW = "League Page Viewed";
export const ARTICLE_PAGE_VIEW = "Article Page Viewed";
export const PROJECTIONS_PAGE_VIEW = "Projections Page Viewed";
export const SITEMAP_PAGE_VIEW = "Sitemap Page Viewed";

// User events
export const NAV_LINK_CLICKED = "Navigation Link Clicked";
export const ARTICLE_LINK_CLICKED = "Article Link Clicked";
export const HOME_CTA = "Home Page Hero CTA Clicked";

// Video events
export const PLAY_VIDEO = "Video Play Successful";
export const PLAY_25_VIDEO = "Video 25% Played";
export const PLAY_50_VIDEO = "Video 50% Played";
export const PLAY_75_VIDEO = "Video 75% Played";
export const COMPLETE_VIDEO = "Video 100% Played";

// Projection events
export const PROJECTIONS_FILTER_APPLIED = "Projections Filter Applied";
export const PROJECTIONS_CSV_DOWNLOADED = "Projections CSV Downloaded";
export const PROJECTIONS_PLAYER_LINK_CLICKED =
  "Projections Player Link Clicked";
export const PROJECTIONS_SORT_APPLIED = "Projections Sort Applied";

// Homepage
export const TOOL_LINK_CLICKED = "Tool Link Clicked";

// Sitemap
export const SITE_MAP_VIEW_SELECTED = "Site Map View Selected";
export const SITE_MAP_DATE_YEAR_SELECTED = "Site Map Date Year Selected";
export const SITE_MAP_DATE_MONTH_SELECTED = "Site Map Date Month Selected";
export const SITE_MAP_DATE_DAY_SELECTED = "Site Map Date Day Selected";
export const SITE_MAP_MODULE = "site_map";

// Tentpole Events
export const EVENT_PAGE_VIEWED = "Tentpole Page Viewed";
export const EVENT_MODAL_VIEWED = "Tentpole Modal Viewed";
export const EVENT_CTA_CLICKED = "Tentpole CTA Clicked";

// Player News Events
export const PLAYER_NEWS_COMPETITION_PAGE_VIEWED =
  "Player News Competition Page Viewed";
export const PLAYER_NEWS_ARTICLE_PAGE_VIEWED =
  "Player News Article Page Viewed";
export const PLAYER_NEWS_FILTER_APPLIED = "Player News Filter Applied";
export const PLAYER_NEWS_SOURCE_LINK_CLICKED =
  "Player News Source Link Clicked";
export const PLAYER_NEWS_AUTHOR_LINK_CLICKED =
  "Player News Author Link Clicked";
export const PLAYER_NEWS_TEAM_LINK_CLICKED = "Player News Team Link Clicked";
export const PLAYER_NEWS_ORIGIN_ARTICLE_CARD = "article_card";
export const PLAYER_NEWS_ORIGIN_ARTICLE_PAGE = "article_page";

// Horse Racing
export const RACING_UPCOMING_RACES_PAGE_VIEWED =
  "Racing: Upcoming Races Page Viewed";
export const RACING_RACE_PAGE_VIEWED = "Racing: Race Page Viewed";
export const RACING_CONSENSUS_PICKS_PAGE_VIEWED =
  "Racing: Consensus Picks Page Viewed";
export const RACING_RACE_SELECTED = "Racing: Race Selected";
export const RACING_RUNNER_ODDS_SELECTED = "Racing: Runner Odds Selected";
export const RACING_TRACKS_LIST_PAGE_VIEWED = "Racing: Tracks List Page Viewed";
export const RACING_TRACKS_FILTER_APPLIED = "Racing: Tracks Filter Applied";
export const RACING_TRACK_LINK_CLICKED = "Racing: Track Link Clicked";
export const RACING_SEARCH_RESULTS_RETURNED = "Racing: Search Results Returned";
export const RACING_FILTER_APPLIED = "Racing: Filter Applied";
export const RACING_PROJECTIONS_CTA_CLICKED = "Racing: Projections CTA Clicked";
export const RACING_SHOW_MORE_CLICKED = "Racing: Show More Clicked";
export const RACING_BETTING_TERMS_PAGE_VIEWED =
  "Racing: Betting Terms Page Viewed";

// Dynamic Odds
export const SPORTSBOOK_ODDS_SELECTED = "Sportsbook Odds Selected";
export const SPORTSBOOK_ODDS_CTA_CLICKED = "Sportsbook Odds CTA Clicked";
export const SPORTSBOOK_BETSLIP_CTA_CLICKED = "Sportsbook Betslip CTA Clicked";
export const SPORTSBOOK_READY_TO_BET_MODAL_CLOSED =
  "Sportsbook Ready To Bet Modal Closed";

// SEO Improvements
export const SPORTSBOOK_PROMO_BANNER_CLICKED =
  "Sportsbook Welcome Promo CTA Clicked";
export const SPORTSBOOK_APP_DOWNLOAD_CLICKED =
  "Sportsbook App Download Link Clicked";
export const SUB_CATEGORY_PAGE_VIEWED = "Sub Category Page Viewed";
export const SUB_CATEGORY_PAGE_CTA_CLICKED = "Sub Category Page CTA Clicked";
export const PROMO_HUB_CARD_CLICKED = "Promo Hub Card Clicked";

// Author Pages
export const AUTHOR_PAGE_VIEWED = "Author Page Viewed";
export const AUTHOR_ARTICLES_EXPANDED = "Author Articles Expanded";
export const AUTHOR_TALENT_LINK_CLICKED = "Author Talent Link Clicked";
export const AUTHOR_FEATURED_BET_CTA_CLICKED =
  "Author Featured Bet CTA Clicked";
export const ARTICLE_FILTER_APPLIED = "Article Filter Applied";
export const AUTHOR_SOCIAL_LINK_CLICKED = "Author Social Link Clicked";
export const AUTHOR_FAVORITE_SPORT_CLICKED = "Author Favorite Sport Clicked";

// NFL Schedule
export const SCHEDULE_PAGE_VIEWED = "Schedule Page Viewed";
export const SCHEDULE_FILTER_APPLIED = "Schedule Filter Applied";
export const SCHEDULE_SORT_APPLIED = "Schedule Sort Applied";
export const SCHEDULE_GAME_CARD_EXPANDED = "Schedule Game Card Expanded";
export const STANDINGS_CONFERENCE_SELECTED = "Standings Conference Selected";
export const STANDINGS_TEAMS_LINK_CLICKED = "Standings Teams Link Clicked";
